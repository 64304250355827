import {
  ShaderMaterial, Mesh, PlaneBufferGeometry, Vector2, Vector3, UniformsUtils
} from 'three'
import SceneRTT from 'components/three/render/SceneRTT';
import vertexShader from "components/three/glsl/vert.vert";
import dotPattern from "components/three/glsl/dotPattern.frag";

let uniforms = {
  time: { value: 0.0 },
  uRadius: { value: 0.0 },
  uScale: { value: 0.0 },
  uSpeedX: { value: 0.0 },
  uSpeedY: { value: 0.0 },
  u_mouse: { type: "v2", value: new Vector2() },
  uColor1: { type: "v3", value: new Vector3() },
  uColor2: { type: "v3", value: new Vector3() },
  u_resolution: { type: "v2", value: new Vector2() },
}

export default class SceneDotRTT extends SceneRTT {
  constructor(w: number, h: number, plane: PlaneBufferGeometry) {
    super(w, h);

    // Dot
    let uniformsDot = UniformsUtils.clone(uniforms);
    uniformsDot.u_resolution.value = new Vector2(w, h);

    let materialDot = new ShaderMaterial({
      uniforms: uniformsDot,
      vertexShader: vertexShader,
      fragmentShader: dotPattern
    });

    this.material = materialDot;
    this.quad = new Mesh(plane, this.material);
    this.scene.add(this.quad);
  }
}