import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, ButtonBase, Typography } from "@material-ui/core";
import React, { useEffect } from 'react'
import { Link, useHistory } from "react-router-dom";
import { useInView } from 'react-intersection-observer'
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  selectConfig,
} from 'features/config/config';
import {
  selectModal,
  selectPages,
  addPageID
} from 'features/pages/pageInfo';

interface MarkdownData {
  url: string,
  title: string,
  hero_image: string,
  content: string
}

const Works = () => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);
  const [ref, inView] = useInView({ threshold: config.intersection.works })
  let history = useHistory();
  const posts = useSelector(selectPages);
  const isModal = useSelector(selectModal);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
      },
      img: {
        width: '100%',
        height: 'auto'
      },
      image: {
        position: 'relative',
        '&:hover, &$focusVisible': {
          zIndex: 1,
          '& $imageBackdrop': {
            opacity: 0.7,
          },
          '& $imageTitle': {
            opacity: 0.7,
          },
        },
      },
      focusVisible: {},
      imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        // top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
      },
      imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.,
        transition: theme.transitions.create('opacity'),
      },
      imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
        textAlign: 'left',
        opacity: 0.,
        transition: theme.transitions.create('opacity'),
      },
    }),
  );

  const classes = useStyles();

  useEffect(() => {
    if (inView && history !== undefined && !isModal) {
      history.push('/works');
    }
  }, [inView])

  return (
    <div id="works" ref={ref}>
      <h2>Works</h2>
      <div className={classes.root}>
        <Grid container spacing={3}>
          {posts.map((post: MarkdownData, idx) => (
            <Grid item xs={12} sm={6} md={3} key={idx}>
              <Link
                key={post.title}
                to={{
                  pathname: `/works/${post.url}`,
                  state:
                  {
                    id: idx,
                    title: post.title,
                    hero_image: post.hero_image,
                    content: post.content,
                  }
                }}
                onClick={() => dispatch(addPageID(idx))}
              >
                <ButtonBase
                  focusRipple
                  className={classes.image}
                  focusVisibleClassName={classes.focusVisible}
                >
                  <LazyLoadImage
                    className={classes.img}
                    src={process.env.PUBLIC_URL + `/img/posts/${post.hero_image}`}
                    alt={post.title}
                    width="100%"
                    height="auto"
                  />
                  <span className={classes.imageBackdrop} />
                  <span className={classes.imageButton}>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="inherit"
                      className={classes.imageTitle}
                    >
                      {post.title}
                    </Typography>
                  </span>
                </ButtonBase>
              </Link>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}
export default withRouter(Works)