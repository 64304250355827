import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Modal, Backdrop, Grid, Fade } from '@material-ui/core';
import gsap, { TweenLite } from "gsap";
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { useHistory, RouteComponentProps } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {
  selectPages,
  selectPagesLength,
  selectModal,
  selectModalUpdating,
  selectVisit,
  selectPageID,
  addModal,
  addModalUpdating,
  addPageID,
} from 'features/pages/pageInfo';
import {
  selectConfig,
} from 'features/config/config';

import 'components/pages/Work.scss';
import PageNav from "components/UI/nav/PageNav";
import PageClose from "components/UI/nav/PageClose";
import ResponsivePlayer, { HostType } from "components/plugins/React-player/ResponsivePlayer";

type TParams = { workId: string };
gsap.registerPlugin(ScrollToPlugin);

const Work = ({ match }: RouteComponentProps<TParams>) => {
  const NAME: string = "Work";
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        marginTop: 100,
        paddingLeft: config.page.paddingSideSM,
        paddingRight: config.page.paddingSideSM,
        [theme.breakpoints.up('md')]: {
          paddingLeft: config.page.paddingSide,
          paddingRight: config.page.paddingSide,
        }
      },
      container: {
        paddingLeft: config.page.paddingSideSM,
        paddingRight: config.page.paddingSideSM,
        [theme.breakpoints.up('md')]: {
          paddingLeft: config.page.paddingSide,
          paddingRight: config.page.paddingSide,
          maxWidth: config.page.maxWidth,
          marginLeft: 'Auto',
          marginRight: 'Auto',
          lineHeight: '2rem',
        },
      },
      mainText: {
        [theme.breakpoints.up('md')]: {
          marginTop: '3rem',
          marginBottom: '3rem',
        },
      },
      modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      footerNav: {
        marginTop: 64,
        marginBottom: 32,
      },
    }),
  );

  const classes = useStyles();
  const pageData = useSelector(selectPages);
  const pageDataLength = useSelector(selectPagesLength);
  const isModal = useSelector(selectModal);
  const isUpdating = useSelector(selectModalUpdating);

  const {
    params: { workId }
  } = match;

  const isVisited = useSelector(selectVisit);
  if (!isVisited) {
    const pathArray = window.location.pathname.split('/');
    const workURL = pathArray[2];
    const workStr = workURL.replace(/-/g, ' ').toLowerCase();
    let index = 0;

    for (let i = 0; i < pageDataLength; i++) {
      const title = pageData[i].title.replace(/-/g, ' ').toLowerCase();

      if (workStr == title) {
        index = i;
        break;
      }
    }
    dispatch(addPageID(index))
  }

  const id = useSelector(selectPageID);
  const handleClose = () => {
  };

  useEffect(() => {
    console.log(NAME, match);
    dispatch(addModal(true));

    return () => {
      dispatch(addModal(false))
    };
  }, [])

  const startTween = () => {
    dispatch(addModalUpdating(true));
  }

  const completeTween = () => {
    dispatch(addModalUpdating(false));
  }

  useEffect(() => {
    const element = document.getElementById("modalInside")!;
    if (element != undefined) {
      TweenLite.to(element, 1, {
        scrollTo: 0,
        onStart: startTween,
        onComplete: completeTween
      });
    }
  }, [id])

  // TODO: Maybe don't use 
  let history = useHistory();
  if (pageData.length == 0) {
    history.push('/');
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModal}>
        <div id="modalInside">
          <div className={classes.root}>
            <div className={isUpdating ? "modalInsideHide" : "modalInsideShow"}>
              <h2 id="workTitle">{pageData[id].title}</h2>
              {/* <p id="transition-modal-description">{pageData[id].date}</p> */}
              <img src={process.env.PUBLIC_URL + `/img/posts/${pageData[id].hero_image}`} alt={pageData[id].title} />

              <p className={`${classes.container} ${classes.mainText} breakText`}>{pageData[id].content}</p>

              {pageData[id].images != null ?

                pageData[id].images.map((post: string, idx: number) => (
                  <img src={process.env.PUBLIC_URL + `/img/posts/${post}`} key={idx} />
                ))
                : ""
              }

              {pageData[id].youtube ? <ResponsivePlayer id={pageData[id].youtube} type={HostType.YouTube} /> : ""}
              {pageData[id].vimeo ? <ResponsivePlayer id={pageData[id].vimeo} type={HostType.Vimeo} /> : ""}
              <p className={`${classes.container} breakText`}>{`Client: ${pageData[id].client}`}</p>
              <p className={`${classes.container} breakText`}>{`Role: ${pageData[id].role}`}</p>
              <p className={`${classes.container} breakText`}>{`Credit: ${pageData[id].credit}`}</p>
              <p className={`${classes.container} breakText`}>{`Tags: ${pageData[id].tags}`}</p>

              {pageData[id].link != null ?
                <p className={`${classes.container} breakText`}>Project link: <a href={pageData[id].link} className="button">{pageData[id].link} </a></p>
                : ""
              }
              {pageData[id].files != null ?
                <p className={`${classes.container} breakText`}>Files: <a href={pageData[id].files} className="button">{pageData[id].files} </a></p>
                : ""
              }
              <div className={classes.footerNav}>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    {id > 0 ? <PageNav data={pageData[id - 1]} baseURL={"/works/"} direct={0} /> : ""}
                  </Grid>
                  <Grid item xs={4}>
                    <PageClose baseURL={"/works"} anchor={"works"} />
                  </Grid>
                  <Grid item xs={4}>
                    {id < pageDataLength - 1 ? <PageNav data={pageData[id + 1]} baseURL={"/works/"} direct={1} /> : ""}
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default withRouter(Work)