import { Hidden, AppBar, Toolbar, Typography, ButtonGroup } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import LinkButton from 'components/UI/buttons/LinkButton'
import LinkInternal from 'components/UI/buttons/LinkInternal'
import LinkExternalButton from 'components/UI/buttons/LinkExternalButton'
import {
  selectConfig,
} from 'features/config/config';
import Logo from 'components/Logo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      position: "fixed",
      top: 0,
      width: '100vw',
      opacity: 0.8,
      zIndex: 2000,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    buttons: {
      display: 'block',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        display: 'inline',
        width: '300px',
      },
    },
  }),
);

export default function ButtonAppBar() {
  const classes = useStyles();
  const config = useSelector(selectConfig);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" style={{ background: '#000000' }}>
        <LinkInternal href={"/"} anchor={"root"}>
          <Hidden mdUp>
            <Logo isSM={true} />
          </Hidden>
        </LinkInternal>
        <Toolbar>
          <Typography variant="h6" align="left" className={classes.title}>
            <LinkInternal href={"/"} anchor={"root"}>
              <Hidden smDown>
                <Logo isSM={false} />
              </Hidden>
            </LinkInternal>
          </Typography>

          <ButtonGroup color="primary" aria-label="outlined primary button group" className={classes.buttons}>
            <LinkButton href={"/works"} anchor={"works"}>
              Works
            </LinkButton>
            <LinkButton href={"/about"} anchor={"about"}>
              About
            </LinkButton>
            <LinkExternalButton href={config.externalLinks.instagram}>
              Instagram
            </LinkExternalButton>
            <LinkExternalButton href={config.externalLinks.contact}>
              Contact
            </LinkExternalButton>
          </ButtonGroup>

        </Toolbar>
      </AppBar >
    </div >
  );
}
