import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from 'features/counter/counterSlice';
import pageReducer from 'features/pages/pageInfo';
import configReducer from 'features/config/config';
import GUIReducer from 'features/GUI/GUIdata';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    pages: pageReducer,
    config: configReducer,
    GUI: GUIReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
