import matter from 'gray-matter';

interface YAMLData {
  id: number,
  date: string,
  url: string,
  title: string,
  hero_image: string,
  images: string[],
  youtube: string,
  vimeo: string,
  link: string,
  files: string,
  role: string,
  client: string,
  credit: string,
  tags: string,
  content: string,
}

export default function parseMarkdownFiles() {
  const importAll = (r: any) => r.keys().map(r);
  const yamlFiles = importAll(require.context('../../posts', false, /\.yaml$/))
    .sort()
    .reverse();

  let yamls: any = [];
  let index: number = 0;
  for (const entry of yamlFiles) {
    let object: YAMLData = {
      id: 0,
      url: "",
      title: "",
      date: "",
      hero_image: "",
      images: [],
      youtube: "",
      vimeo: "",
      link: "",
      files: "",
      role: "",
      client: "",
      credit: "",
      tags: "",
      content: "",
    };

    const m = matter(entry.default);

    // ID
    object.id = index;
    // URL
    object.url = m.data.title.replace(/\s+/g, '-').toLowerCase();
    // Title
    object.title = m.data.title;
    object.date = m.data.date;
    object.hero_image = m.data.hero_image;
    object.images = m.data.images;
    object.youtube = m.data.youtube;
    object.vimeo = m.data.vimeo;
    object.link = m.data.link;
    object.files = m.data.files;
    object.tags = m.data.tags;
    object.client = m.data.client;
    object.credit = m.data.credit;
    object.role = m.data.role;
    // Content
    object.content = m.content;

    yamls.push(object);
    index++;
  }
  return yamls;
}