import { WebGLRenderer } from 'three'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer'
import { selectConfig } from 'features/config/config';
import { addInViewPlayground, addPlaygroundIndex } from 'features/pages/pageInfo';

import PopScene from "./PopScene";

import './ThreeContainer.scss';
type ContentProps = {
  data: any;
}
const ThreeContainer: React.FC<ContentProps> = props => {
  const { data } = props;
  const config = useSelector(selectConfig);
  const [ref, inView] = useInView({ threshold: config.intersection.playground })
  const [rendererProp, setRenderer] = useState<any>(null);
  const dispatch = useDispatch();


  let renderer: WebGLRenderer;
  let canvas: HTMLCanvasElement;

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    dispatch(addPlaygroundIndex(data.select));
  }, [data])

  useEffect(() => {
    dispatch(addInViewPlayground(inView));
  }, [inView])

  const init = () => {
    // Canvas
    canvas = document.getElementById('canvas') as HTMLCanvasElement;

    // Render
    initRender();
  }

  // init render
  const initRender = () => {
    // Renderer
    renderer = new WebGLRenderer({ canvas: canvas, antialias: true });
    renderer.setClearColor('#000000');
    renderer.autoClear = false;
    
    // Set renderer into state
    setRenderer(renderer);
  }

  return (
    <div ref={ref}>
      <PopScene id={0} renderer={rendererProp} />
      <canvas id={'canvas'} />
    </div>
  )
}

export default ThreeContainer