import { IconButton, Box, Grid, useMediaQuery } from '@material-ui/core';
import { Twitter, GitHub, Instagram, Facebook, Mail } from '@material-ui/icons';
import { makeStyles, createStyles, useTheme, Theme } from '@material-ui/core/styles';

import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer'
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';

import {
  selectConfig,
} from 'features/config/config';
import {
  selectModal,
} from 'features/pages/pageInfo';

import LinkExternal from 'components/UI/buttons/LinkExternal'

const AboutPage = () => {
  const config = useSelector(selectConfig);
  const isModal = useSelector(selectModal);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [ref, inView] = useInView({ threshold: config.intersection.about })
  let history = useHistory();
  const intro = config.about.intro;
  const proficiency = config.about.proficiency;
  const software = config.about.software;
  const language = config.about.language;
  const love = config.about.love;

  useEffect(() => {
    if (inView && history !== undefined && !isModal) {
      history.push('/about');
    }
  }, [inView])

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        paddingLeft: config.page.paddingSideSM,
        paddingRight: config.page.paddingSideSM,
        [theme.breakpoints.up('md')]: {
          paddingLeft: config.page.paddingSide,
          paddingRight: config.page.paddingSide,
          maxWidth: config.page.maxWidth,
          marginLeft: 'Auto',
          marginRight: 'Auto',
          lineHeight: '2rem',
        },
      },
      text: {
        textAlign: 'left',
        marginBottom: '2rem',
      },
      item: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(1),
        },
      },
      title: {
        marginTop: '1rem',
        textAlign: 'center',
        padding: 0,
        [theme.breakpoints.up('md')]: {
          marginTop: 0,
          textAlign: 'right',
        },
      },
      list: {
        margin: 0,
        textAlign: 'left',
        display: 'block',
        width: '100%',
        padding: 0,
        [theme.breakpoints.up('md')]: {
          textAlign: 'left',
          lineHeight: '2rem',
        },
      },
      iconList: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
          margin: theme.spacing(1),
        },
      },
    }),
  );

  const classes = useStyles();

  return (
    <div id="about" className={classes.root} ref={ref}>
      <h2>About</h2>
      <p className={classes.text}>{intro}</p>

      <Grid container spacing={matches ? 5 : 1} className={classes.item}>

        <Grid item xs={12} sm={3}>
          <h3 className={classes.title}>Proficiency</h3>
        </Grid>
        <Grid item xs={12} sm={9}>
          <p className={`${classes.list} breakText`}>{proficiency}</p>
        </Grid>
      </Grid>

      <Grid container spacing={matches ? 5 : 1} className={classes.item}>
        <Grid item xs={12} sm={3}>
          <h3 className={classes.title}>Software</h3>
        </Grid>
        <Grid item xs={12} sm={9}>
          <p className={`${classes.list} breakText`}>{software}</p>
        </Grid>
      </Grid>

      <Grid container spacing={matches ? 5 : 1} className={classes.item}>
        <Grid item xs={12} sm={3}>
          <h3 className={classes.title}>Language</h3>
        </Grid>
        <Grid item xs={12} sm={9}>
          <p className={`${classes.list} breakText`}>{language}</p>
        </Grid>
      </Grid>

      <Grid container spacing={matches ? 5 : 1} className={classes.item}>
        <Grid item xs={12} sm={3}>
          <h3 className={classes.title}>Love</h3>
        </Grid>
        <Grid item xs={12} sm={9}>
          <p className={`${classes.list} breakText`}>{love}</p>
        </Grid>
      </Grid>

      {/* <h3>Show and Exhibition</h3> */}

      <div className={classes.iconList}>
        <Box display="flex" flexDirection="row" p={1} m={1}>
        <IconButton color="primary" aria-label="back" component="span">
            <LinkExternal href={config.externalLinks.instagram}> <Instagram fontSize="large" />
            </LinkExternal>
          </IconButton>
          <IconButton color="primary" aria-label="back" component="span">
            <LinkExternal href={config.externalLinks.github}> <GitHub fontSize="large" />
            </LinkExternal>
          </IconButton>
          <IconButton color="primary" aria-label="back" component="span">
            <LinkExternal href={config.externalLinks.twitter}> <Twitter fontSize="large" />
            </LinkExternal>
          </IconButton>
          <IconButton color="primary" aria-label="back" component="span">
            <LinkExternal href={config.externalLinks.facebook}> <Facebook fontSize="large" />
            </LinkExternal>
          </IconButton>
          <IconButton color="primary" aria-label="back" component="span">
            <LinkExternal href={config.externalLinks.contact}> <Mail fontSize="large" />
            </LinkExternal>
          </IconButton>
        </Box>
      </div>
    </div>
  )
}
export default AboutPage