import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { addPageData, addOnLoad, selectOnLoad, selectModal } from 'features/pages/pageInfo';
import { addConfig } from 'features/config/config';
import parseYAMLFiles from 'components/utils/YAMLParser';
import Works from 'components/pages/Works';
import WorksDetail from 'components/pages/WorksDetail';
import About from 'components/pages/About';
import Contact from 'components/pages/Contact';
import HeaderBar from 'components/UI/header/HeaderBar';
import Playground from 'components/three/Playground'
import * as config from './config/config.js';
import 'App.scss';

function App() {
  const bodyElement: HTMLElement = document.body;
  const dispatch = useDispatch();
  // TODO: Specify absolute path with require.context
  const yamls = parseYAMLFiles();
  dispatch(addPageData(yamls))
  dispatch(addConfig(config))
  const isOnLoad = useSelector(selectOnLoad);
  const isModal = useSelector(selectModal);

  useEffect(() => {
    dispatch(addOnLoad(true));
  }, [])

  useEffect(() => {
    if (isModal) {
      // Fix position for body scrolling
      bodyElement.style.top = '-' + window.pageYOffset + 'px';
    }
  }, [isModal])

  useEffect(() => {
    if (isOnLoad) {
      console.log('Loaded');
    } else {
      console.log('Start loading...');
    }
  }, [isOnLoad])

  return (
    <div>
      {isOnLoad
        ?  <div className="App">
        <Router>
          <header className="App-header" style={{ background: '#000000' }}>
            <Playground />
          </header>

          <Route path="/works">
            <WorksDetail />
          </Route>
          <HeaderBar />

          <Works />
          <About />
          <Contact />
        </Router>
      </div>
        : <div></div>
      }
     
    </div>
  );
}

export default App;