import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';

interface GuiState {
  data: any;
  toggle: boolean;
}

const initialState: GuiState = {
  data: {},
  toggle: false,
};

export const slice = createSlice({
  name: 'GUI',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    addData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    addToggle: (state, action: PayloadAction<any>) => {
      state.toggle = action.payload;
    },
  },
});

export const { addData, addToggle } = slice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectData = (state: RootState) =>
  state.GUI.data;
export const selectToggle = (state: RootState) =>
  state.GUI.toggle;

export default slice.reducer;
