import React from 'react';

type LinkExternalProps = {
  href: string;
  children: any;
}
const LinkExternal: React.FC<LinkExternalProps> = props => {
  const { href, children } = props;

  return (
    <a href={href} target="_blank" className={"button"}>
      {children}
    </a>
  );
}

export default LinkExternal