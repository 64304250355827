module.exports = {
  "about": {
    "intro": "Yasushi Harada aka chimanaco is an interactive developer, technical director and visual artist born in Tokyo, Japan, currently living in Vancouver. With a background in psychology, he works in a wide range of fields including interactive installations, data visualization, generative design, web, apps, magazine and real-time music visualization for brand activation, museum exhibitions, night club events and music festivals to create aesthetic experience. He is a founder and co-organizer of the TouchDesigner Study Group and has done workshops in Canada and China. He is always eager to work with someone weird to make our world even weirder. TDSW (Tokyo Developers Study Weekend) producer/director.",
    "proficiency": "Interactive installation, live visuals, technical direction and consultant, instructor, projection mapping, web development, app development, event organization",
    "software": "TouchDesigner, Unreal Engine, Unity, Photoshop, Illustrator, AfterEffects, Processing, openFrameworks, MadMapper, Arduino, Houdini, Sketch, VDMX, Resolume, Flash",
    "language": "JavaScript (including WebGL, React, Redux, node.js), GLSL, Python, C++, C#, PHP, MongoDB, html5, CSS3, Java, ActionScript, Lua",
    "love": "Soccer, cinema, books, manga, music, travel, sweets, sneakers, gadgets, programming, languages, cafe",
  },
  "page": {
    "maxWidth": 960,
    "paddingSide": 24,
    "paddingSideSM": 16,
  },
  "externalLinks": {
    "contact": "https://docs.google.com/forms/d/1VD7swxCYsyl-RjQ6RzsvnyEzhnB6tItdDBA3m9MtdU0/edit",
    "twitter": "https://twitter.com/chimanaco",
    "instagram": "https://instagram.com/chimanaco",
    "github": "https://github.com/chimanaco",
    "facebook": "https://www.facebook.com/chimanaco",
  },
  "scroll": {
    "offset": 90,
    "offsetSM": 110,
  },
  "intersection": {
    "playground": 0.2,
    "works": 0.5,
    "about": 0.5,
  },
  "greeting": [
    "heroic",
    "remarkable",
    "delightful",
    "fascinate",
    "terrific",
    "noteworthy",
    "enviable",
    "fantastic",
    "impressive ",
    "commendable",
  ]
}
