import DatGui, { DatButton, DatColor, DatNumber, DatSelect } from "@tim-soft/react-dat-gui";
import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { addData, addToggle, selectData, selectToggle } from 'features/GUI/GUIdata';
import ThreeContainer from 'components/three/scenes/ThreeContainer'

const initialState: any = {
    data: {
        string: "Hello World",
        minMaxNumber: 66,
        number: 80,
        boolean: true,
        select: 0,
        color: "#2FA1D6",
        random: Math.random(),
        nested: {
            string: "Goodbye Cruel World"
        }
    },
    toggle: false
};

const Playground: React.FC = props => {
    const D_KEY: number = 68;
    const data = useSelector(selectData);
    const toggle = useSelector(selectToggle);
    const dispatch = useDispatch();
    const toggleRef = useRef(false);

    // Update random number for current state
    const handleButtonClick = () => {
        dispatch(addData(initialState.data));
    }

    // Update current state with changes from controls
    const handleUpdate = (data: any) =>
        dispatch(addData(data))

    useEffect(() => {
        dispatch(addData(initialState.data));
        dispatch(addToggle(initialState.toggle));

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [])

    const handleKeyDown = (event: KeyboardEvent) => {
        switch (event.keyCode) {
            case D_KEY:
                toggleRef.current = !toggleRef.current;
                dispatch(addToggle(toggleRef.current));
                break;
            default:
                break;
        }
    }

    return (
        <>
            <ThreeContainer data={data} />
            <div style={{ visibility: toggle ? "visible" : "hidden" }}>
                <DatGui
                    data={data}
                    onUpdate={handleUpdate}
                    className="react-dat-gui-relative-position"
                >
                    <DatNumber
                        path="minMaxNumber"
                        label="Number"
                        min={0}
                        max={100}
                        step={1}
                    />
                    <DatButton label="Button" onClick={handleButtonClick} />
                    <DatSelect
                        label="Select"
                        path="select"
                        options={[0, 1, 2]}
                    />
                    <DatColor label="Color" path="color" />
                </DatGui>
            </div>
        </>
    );
}

export default Playground;
