import {
  ShaderMaterial, Mesh, PlaneBufferGeometry, Vector2, Vector3, UniformsUtils
} from 'three'
import SceneRTT from 'components/three/render/SceneRTT';
import vertexShader from "components/three/glsl/vert.vert";
import gradient from "components/three/glsl/gradient.frag";

let uniforms = {
  time: { value: 0.0 },
  u_mouse: { type: "v2", value: new Vector2() },
  u_resolution: { type: "v2", value: new Vector2() },
  uColor1: { type: "v3", value: new Vector3() },
  uColor2: { type: "v3", value: new Vector3() },
}

export default class SceneGradRTT extends SceneRTT {
  constructor(w: number, h: number, plane: PlaneBufferGeometry) {
    super(w, h);

    // Noise
    let uniformsNoise = UniformsUtils.clone(uniforms);
    uniformsNoise.u_resolution.value = new Vector2(w, h);

    let materialNoise = new ShaderMaterial({
      uniforms: uniformsNoise,
      vertexShader: vertexShader,
      fragmentShader: gradient
    });

    this.material = materialNoise;
    this.quad = new Mesh(plane, this.material);
    this.scene.add(this.quad);
  }
}