import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface PageState {
  data: Array<any>;
  dataLength: number;
  onLoad: boolean;
  modal: boolean;
  modalUpdating: boolean;
  inViewPlayground: boolean;
  playgroundIndex: number;
  visit: boolean;
  pageID: number;
}

const initialState: PageState = {
  data: [],
  dataLength: 0,
  onLoad: false,
  modal: false,
  modalUpdating: false,
  inViewPlayground: false,
  playgroundIndex: 0,
  visit: false,
  pageID: 2,
};

export const slice = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    addPageData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.dataLength = state.data.length;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    addOnLoad: (state, action: PayloadAction<any>) => {
      state.onLoad = action.payload;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    addModal: (state, action: PayloadAction<any>) => {
      state.modal = action.payload;
    },
    addModalUpdating: (state, action: PayloadAction<any>) => {
      state.modalUpdating = action.payload;
    },
    addInViewPlayground: (state, action: PayloadAction<any>) => {
      state.inViewPlayground = action.payload;
    },
    addPlaygroundIndex: (state, action: PayloadAction<any>) => {
      state.playgroundIndex = action.payload;
    },
    addVisit: (state, action: PayloadAction<any>) => {
      state.visit = action.payload;
    },
    addPageID: (state, action: PayloadAction<any>) => {
      state.pageID = action.payload;
    },
  },
});

export const { addPageData, addOnLoad, addModal, addModalUpdating, addInViewPlayground, addPlaygroundIndex, addVisit, addPageID } = slice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectPages = (state: RootState) =>
  state.pages.data;
export const selectPagesLength = (state: RootState) =>
  state.pages.dataLength;
export const selectOnLoad = (state: RootState) =>
  state.pages.onLoad;
export const selectModal = (state: RootState) =>
  state.pages.modal;
export const selectModalUpdating = (state: RootState) =>
  state.pages.modalUpdating;
export const selectInViewPlayground = (state: RootState) =>
  state.pages.inViewPlayground;
export const selectPlaygroundIndex = (state: RootState) =>
  state.pages.playgroundIndex;
export const selectVisit = (state: RootState) =>
  state.pages.visit;
export const selectPageID = (state: RootState) =>
  state.pages.pageID;

export default slice.reducer;
