import {
  WebGLRenderTarget, Scene, NearestFilter, RGBFormat, LinearFilter, Mesh, ShaderMaterial, WebGLRenderer, OrthographicCamera
} from 'three'

export default class SceneRTT {
  public scene: Scene = new Scene();
  public rtTexture!: WebGLRenderTarget;
  public quad!: Mesh;
  public material!: ShaderMaterial;

  constructor(w: number, h: number) {
    this.rtTexture = new WebGLRenderTarget(w, h, { minFilter: LinearFilter, magFilter: NearestFilter, format: RGBFormat });
  }

  public render(renderer: WebGLRenderer, cameraRTT: OrthographicCamera) {
    renderer.setRenderTarget(this.rtTexture);
    renderer.clear();
    renderer.render(this.scene, cameraRTT);
  }
}