import React, { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import anchorLink from 'components/utils/AnchorLink';
import {
  selectConfig,
} from 'features/config/config';

// Introのpropsのtype aliasを定義
type ContentProps = {
  href: string;
  anchor: string;
  children: any;
}
const LinkButton: React.FC<ContentProps> = props => {
  const { href, anchor, children } = props;
  const config = useSelector(selectConfig);
  let history = useHistory();

  function handleClick(e: MouseEvent) {
    history.push(href);
    e.preventDefault();
    anchorLink(anchor, config.scroll.offset);
  }

  return (
    <a href={href} onClick={handleClick} className={"button"}>
      {children}
    </a>
  );
}

export default LinkButton