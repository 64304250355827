import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface PageState {
  data: any;
}

const initialState: PageState = {
  data: {},
};

export const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    addConfig: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});

export const { addConfig } = slice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectConfig = (state: RootState) =>
  state.config.data;

export default slice.reducer;
