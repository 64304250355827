import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import Work from "components/pages/Work";

function WorksDetail() {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  let { path } = useRouteMatch();

  return (
    <Route path={`${path}/:workId`} component={Work}>
    </Route>
  );
}
export default WorksDetail