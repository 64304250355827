import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import {
  WebGLRenderer, Scene, ShaderMaterial, Mesh, Vector2, PlaneBufferGeometry, OrthographicCamera, Vector3
} from 'three'
import {
  selectInViewPlayground, selectPlaygroundIndex,
} from 'features/pages/pageInfo';
import vertexShader from "components/three/glsl/vert.vert";
import fragScreen from "components/three/glsl/fragScreen.frag";
import debounce from "components/utils/debounce";
import SceneDotRTT from 'components/three/render/SceneDotRTT';
import SceneMetalRTT from 'components/three/render/SceneMetaballRTT';
import SceneLineRTT from 'components/three/render/SceneLineRTT';
import SceneGradRTT from 'components/three/render/SceneGradRTT';

// Introのpropsのtype aliasを定義
type SceneProps = {
  id: number;
  renderer: WebGLRenderer;
}

const PopScene: React.FC<SceneProps> = props => {
  let { id, renderer } = props;
  const activeRef = useRef(true);
  const intervalRef = useRef<number>(0);
  const uniformsRef = useRef<any>(null);
  const active = useSelector(selectInViewPlayground);
  const index = useSelector(selectPlaygroundIndex);

  const delta: number = 0.01;
  const delay: number = 2000;

  // let scene: Scene;
  let camera: OrthographicCamera;
  let sceneScreen: Scene;

  let cameraRTT: OrthographicCamera;

  let mouseX = 0;
  let mouseY = 0;

  let line0RTT: SceneLineRTT;
  let gradRTT: SceneGradRTT;
  let dot1RTT: SceneDotRTT;
  let noise1RTT: SceneMetalRTT;
  let dot2RTT: SceneDotRTT;
  let noise2RTT: SceneMetalRTT;
  let dot3RTT: SceneDotRTT;
  let line3RTT: SceneLineRTT;
  let noise3RTT: SceneMetalRTT;

  useEffect(() => {
    // TODO: Got error when I use `===` why does index become string?
    if ((index == id) && active) {
      activeRef.current = true;
      intervalRef.current = window.setInterval(generateRandomValues, delay);
    } else {
      activeRef.current = false;
      window.clearInterval(intervalRef.current);
    }
  }, [index, active])

  useEffect(() => {
    if (renderer != null) {
      const w = window.innerWidth;
      const h = window.innerHeight;
      renderer.setSize(w, h);
      init();
    }
  }, [renderer])

  // Init camera
  const init = () => {
    const w = window.innerWidth;
    const h = window.innerHeight;

    // camera = new PerspectiveCamera(30, window.innerWidth / h, 1, 10000);
    camera = new OrthographicCamera(w / - 2, w / 2, h / 2, h / - 2, - 10000, 10000);
    camera.position.z = 100;

    cameraRTT = new OrthographicCamera(w / - 2, w / 2, h / 2, h / - 2, - 10000, 10000);
    cameraRTT.position.z = 100;

    const plane = new PlaneBufferGeometry(w, h);

    // RenderTextureTarget
    initRTT(w, h, plane);

    // Screen
    initScreen(w, h, plane);

    // add postprocessing
    animate();

    // Add mouse action
    document.addEventListener('mousemove', onDocumentMouseMove, false);
    // Add Resize listener
    window.addEventListener('resize', debouncedHandleResize);
  }

  const onDocumentMouseMove = (event: MouseEvent) => {
    const w = window.innerWidth;
    const h = window.innerHeight;
    mouseX = (event.clientX - w / 2);
    mouseY = (event.clientY - h / 2);
  }

  const debouncedHandleResize = debounce(function handleResize() {
    const w = window.innerWidth;
    const h = window.innerHeight;
    // camera.aspect = w / h;
    cameraRTT = new OrthographicCamera(w / - 2, w / 2, h / 2, h / - 2, - 10000, 10000);

    camera.updateProjectionMatrix();
    renderer.setSize(w, h);
  }, 1000)

  // Animate
  const animate = () => {
    requestAnimationFrame(animate);
    render();
  }

  // Render
  const render = () => {
    const w = window.innerWidth;
    const h = window.innerHeight;

    // Uniforms
    line0RTT.material.uniforms["time"].value += delta;
    line0RTT.material.uniforms["u_mouse"].value = new Vector2(mouseX / w / 2, mouseY / h / 2);

    gradRTT.material.uniforms["time"].value += delta;
    gradRTT.material.uniforms["u_mouse"].value = new Vector2(mouseX / w / 2, mouseY / h / 2);

    dot1RTT.material.uniforms["time"].value += delta;
    dot1RTT.material.uniforms["u_mouse"].value = new Vector2(mouseX / w / 2, mouseY / h / 2);

    noise1RTT.material.uniforms["time"].value += delta;
    noise1RTT.material.uniforms["u_mouse"].value = new Vector2(mouseX / w / 2, mouseY / h / 2);

    dot2RTT.material.uniforms["time"].value += delta;
    dot2RTT.material.uniforms["u_mouse"].value = new Vector2(mouseX / w / 2, mouseY / h / 2);

    noise2RTT.material.uniforms["time"].value += delta;
    noise2RTT.material.uniforms["u_mouse"].value = new Vector2(mouseX / w / 2, mouseY / h / 2);

    dot3RTT.material.uniforms["time"].value += delta;
    dot3RTT.material.uniforms["u_mouse"].value = new Vector2(mouseX / w / 2, mouseY / h / 2);

    line3RTT.material.uniforms["time"].value += delta;
    line3RTT.material.uniforms["u_mouse"].value = new Vector2(mouseX / w / 2, mouseY / h / 2);

    noise3RTT.material.uniforms["time"].value += delta;
    noise3RTT.material.uniforms["u_mouse"].value = new Vector2(mouseX / w / 2, mouseY / h / 2);

    // Render first scene into texture
    line0RTT.render(renderer, cameraRTT);
    gradRTT.render(renderer, cameraRTT);
    dot1RTT.render(renderer, cameraRTT);
    noise1RTT.render(renderer, cameraRTT);
    dot2RTT.render(renderer, cameraRTT);
    noise2RTT.render(renderer, cameraRTT);
    dot3RTT.render(renderer, cameraRTT);
    line3RTT.render(renderer, cameraRTT);
    noise3RTT.render(renderer, cameraRTT);
    
    // Render full screen quad with generated texture

    renderer.setRenderTarget(null);
    renderer.clear();
    renderer.render(sceneScreen, cameraRTT);

    // Render second scene to screen
    // (using first scene as regular texture)

    // renderer.render(scene, camera);
  }

  // Init RTT
  const initRTT = (w: number, h: number, plane: PlaneBufferGeometry) => {
    // Line0
    line0RTT = new SceneLineRTT(w, h, plane);
    line0RTT.material.uniforms["uRepeat"].value = 25;
    line0RTT.material.uniforms["uSpeed"].value = 2;
    line0RTT.material.uniforms["uAngle"].value = -45;
    line0RTT.material.uniforms["uColor1"].value = new Vector3(1.0, 1.0, 1.0);
    line0RTT.material.uniforms["uColor2"].value = new Vector3(0.545098, 0.572549, 0.372549);
 
    // Gradient
    gradRTT = new SceneGradRTT(w, h, plane);
    gradRTT.material.uniforms["uColor1"].value = new Vector3(0.435294,0.792157,0.8);
    gradRTT.material.uniforms["uColor2"].value = new Vector3(0.941176,0.431373,0.490196);

    // Dot1
    dot1RTT = new SceneDotRTT(w, h, plane);
    dot1RTT.material.uniforms["uColor1"].value = new Vector3(0.0862745, 0.376471, 0.647059);
    dot1RTT.material.uniforms["uColor2"].value = new Vector3(0.666667, 0.380392, 0.647059);
    dot1RTT.material.uniforms["uRadius"].value = 0.15;
    dot1RTT.material.uniforms["uScale"].value = 5;
    dot1RTT.material.uniforms["uSpeedX"].value = 0.25;
    dot1RTT.material.uniforms["uSpeedY"].value = 0.25;

    // Noise1
    noise1RTT = new SceneMetalRTT(w, h, plane);
    noise1RTT.material.uniforms["u_scale"].value = 1;
    noise1RTT.material.uniforms["uStep"].value = 0.065;
    noise1RTT.material.uniforms["uOffset"].value = 1.2831;

    // Dot2
    dot2RTT = new SceneDotRTT(w, h, plane);
    dot2RTT.material.uniforms["uColor1"].value = new Vector3(0.117647, 0.258824, 0.341176);
    dot2RTT.material.uniforms["uColor2"].value = new Vector3(0.227451, 0.760784, 0.956863);
    dot2RTT.material.uniforms["uScale"].value = 10;
    dot2RTT.material.uniforms["uRadius"].value = 0.5;
    dot2RTT.material.uniforms["uSpeedX"].value = -0.15;
    dot2RTT.material.uniforms["uSpeedY"].value = -0.15;

    // Noise2
    noise2RTT = new SceneMetalRTT(w, h, plane);
    noise2RTT.material.uniforms["u_scale"].value = 1.1;
    noise2RTT.material.uniforms["uStep"].value = 0.07;
    noise2RTT.material.uniforms["uOffset"].value = 3.2831;

    // Dot3
    dot3RTT = new SceneDotRTT(w, h, plane);
    dot3RTT.material.uniforms["uColor1"].value = new Vector3(0.666667, 0.380392, 0.647059);
    dot3RTT.material.uniforms["uColor2"].value = new Vector3(0.133333, 0.294118, 0.8);
    dot3RTT.material.uniforms["uRadius"].value = 0.1;
    dot3RTT.material.uniforms["uScale"].value = 10;
    dot3RTT.material.uniforms["uSpeedX"].value = -0.15;
    dot3RTT.material.uniforms["uSpeedY"].value = -0.15;

    // Line3
    line3RTT = new SceneLineRTT(w, h, plane);
    line3RTT.material.uniforms["uRepeat"].value = 100;
    line3RTT.material.uniforms["uSpeed"].value = 10;
    line3RTT.material.uniforms["uAngle"].value = 0;
    line3RTT.material.uniforms["uColor1"].value = new Vector3(0.886275, 0.780392, 0.807843);
    line3RTT.material.uniforms["uColor2"].value = new Vector3(0.87451, 0.109804, 0.615686);

    // Noise3
    noise3RTT = new SceneMetalRTT(w, h, plane);
    noise3RTT.material.uniforms["u_scale"].value = -1.1;
    noise3RTT.material.uniforms["uStep"].value = 0.08;
    noise3RTT.material.uniforms["uOffset"].value = 6.2831;
  }

  // Init plane
  const initScreen = (w: number, h: number, plane: PlaneBufferGeometry) => {
    // scene = new Scene();
    sceneScreen = new Scene();

    // Screen
    let uniformsScreen = {
      tGrad: { value: gradRTT.rtTexture.texture },
      tLine0: { value: line0RTT.rtTexture.texture },
      tDot1: { value: dot1RTT.rtTexture.texture },
      tNoise1: { value: noise1RTT.rtTexture.texture },
      tDot2: { value: dot2RTT.rtTexture.texture },
      tNoise2: { value: noise2RTT.rtTexture.texture },
      tDot3: { value: dot3RTT.rtTexture.texture },
      tLine3: { value: line3RTT.rtTexture.texture },
      tNoise3: { value: noise3RTT.rtTexture.texture },
    }

    const materialScreen = new ShaderMaterial({
      uniforms: uniformsScreen,
      vertexShader: vertexShader,
      fragmentShader: fragScreen,
      depthWrite: false
    });

    const quad:Mesh = new Mesh(plane, materialScreen);
    sceneScreen.add(quad);
    // uniformsRef.current = uniformsS;
  }

  // Generate Random Values
  const generateRandomValues = () => {
    if (!uniformsRef.current) {
      return;
    }
    uniformsRef.current.u_speed.value = Math.random() * 10 - 5;
    uniformsRef.current.u_rotation.value = Math.random() * 3.14;
    uniformsRef.current.u_grid.value.x = Math.random() * 100;
    uniformsRef.current.u_grid.value.y = Math.random() * 100;
    uniformsRef.current.u_mouse.value.x = Math.random() * 256 - 128;
    uniformsRef.current.u_mouse.value.y = Math.random() * 256 - 128;
  }
  return (
    <div>
    </div>
  )
}

export default PopScene