import React from "react";
import { Button } from "@material-ui/core";

type LinkExternalProps = {
  href: string;
  children: any;
}
const LinkExternalButton: React.FC<LinkExternalProps> = props => {
  const { href, children } = props;

  return (
    <Button color="inherit" className={"button"} href={href} style={{ "textTransform": "none" }} target = "_blank" >
  { children }
    </Button >
  );
}

export default LinkExternalButton