import { Cancel } from '@material-ui/icons';
import React from 'react';
import LinkInternal from 'components/UI/buttons/LinkInternal'

type NavProps = {
  baseURL: string;
  anchor: string;
}
const PageClose: React.FC<NavProps> = props => {
  const { baseURL, anchor } = props;
  return (
    <div className="center">
      <LinkInternal href={baseURL} anchor={anchor}>
        <Cancel fontSize="large" />
      </LinkInternal>
    </div>
  );
}
export default PageClose