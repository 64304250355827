import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

import {
  selectConfig,
} from 'features/config/config';
import useInterval from 'components/utils/useInterval';

const ContactPage = () => {
  const delay: number = 10000;
  const config = useSelector(selectConfig);
  const [words, setWords] = useState("");
  const greeting = config.greeting;
  const len = greeting.length;

  useEffect(() => {
    const num = Math.floor(Math.random() * len);
    setWords(greeting[num])
  }, [])

  // Increment the counter.
  useInterval(() => {
    const num = Math.floor(Math.random() * len);
    setWords(greeting[num])
  }, delay);

  return (
    <div id="Contact">
      <p>How {words} you are to visit me :)</p>
    </div>
  )
}
export default ContactPage