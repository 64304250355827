import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import './Logo.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoSM: {
      width: '150px',
      margin: '20px auto 0',
    },
    logo: {
      width: '200px'
    },
  }),
);

const Logo = (prop: any) => {
  const classes = useStyles();
  const { isSM } = prop;
  let logoID: string = "";
  let gradientID: string = "";
  let animationID: string = "";
  if (isSM) {
    logoID = "svg-logo-sm"
    animationID = "logo-gradient-sm"
    gradientID = "gradient-vertical-sm"
  } else {
    logoID = "svg-logo"
    animationID = "logo-gradient"
    gradientID = "gradient-vertical"
  }

  return (
    <svg className={isSM ? classes.logoSM : classes.logo} id={logoID} viewBox="0 0 300 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id={animationID} x1="100%" y1="100%">
          <stop offset="0%">
            <animate attributeName="stop-color" values="#662DBC;#ED1E79;#D4145A;#FBB03B;#662DBC" dur="7s" repeatCount="indefinite" />
          </stop>
          <stop offset="100%">
            <animate attributeName="stop-color" values="#ED1E79;#D4145A;#FBB03B;#662DBC;#ED1E79" dur="7s" repeatCount="indefinite" />
          </stop>
        </linearGradient>
      </defs>
      <path d="M27.3349 16.3631C32.2107 21.4184 28.3804 32.5963 15.803 32.5963C5.74374 32.5963 0 24.3673 0 16.3631C0 8.35883 5.74374 0.129894 15.7995 0.129894C28.377 0.129894 32.2005 11.3113 27.3314 16.3631" fill="#241916" />
      <path d="M261.284 16.3631C266.159 21.4184 262.329 32.5963 249.752 32.5963C239.699 32.5963 233.952 24.3673 233.952 16.3631C233.952 8.35883 239.696 0.129894 249.752 0.129894C262.329 0.129894 266.153 11.3113 261.284 16.3631Z" fill="#241916" />
      <path d="M160.811 22.3698L153.01 4.78149C152.461 3.49412 151.558 2.39907 150.413 1.63016C149.268 0.861246 147.929 0.451771 146.561 0.451771C145.193 0.451771 143.854 0.861246 142.709 1.63016C141.564 2.39907 140.661 3.49412 140.112 4.78149L132.314 22.3698C131.933 23.2877 131.732 24.2737 131.722 25.2716C131.711 26.2694 131.893 27.2596 132.255 28.1855C132.617 29.1113 133.154 29.9548 133.833 30.6676C134.513 31.3805 135.323 31.9488 136.216 32.34C139.541 33.7969 144.379 32.5541 146.563 29.5771C148.743 32.5541 153.584 33.7899 156.909 32.34C157.802 31.9488 158.612 31.3805 159.292 30.6676C159.972 29.9548 160.508 29.1113 160.87 28.1855C161.233 27.2596 161.414 26.2694 161.404 25.2716C161.394 24.2737 161.192 23.2877 160.811 22.3698V22.3698Z" fill="#241916" />
      <path d="M73.6572 7.7234C73.6572 5.83471 74.3874 4.02337 75.6872 2.68786C76.9871 1.35235 78.75 0.602074 80.5883 0.602074C82.4265 0.602074 84.1895 1.35235 85.4893 2.68786C86.7891 4.02337 87.5194 5.83471 87.5194 7.7234V25.0028C87.5194 26.8915 86.7891 28.7028 85.4893 30.0383C84.1895 31.3738 82.4265 32.1241 80.5883 32.1241C78.75 32.1241 76.9871 31.3738 75.6872 30.0383C74.3874 28.7028 73.6572 26.8915 73.6572 25.0028V7.7234Z" fill="#241916" />
      <path d="M187.377 0.603828C186.187 0.604264 185.017 0.920168 183.98 1.52101C182.943 2.12185 182.075 2.9873 181.459 4.03372C179.477 2.06074 176.542 0.603828 173.515 0.603828C171.677 0.603828 169.915 1.35392 168.615 2.6891C167.315 4.02428 166.585 5.83517 166.585 7.7234V25.0028C166.585 26.5567 167.08 28.0679 167.994 29.3057C168.909 30.5435 170.192 31.4398 171.649 31.8576C173.106 32.2755 174.655 32.1919 176.062 31.6197C177.468 31.0476 178.653 30.0182 179.436 28.6889C181.418 30.6619 184.35 32.1188 187.377 32.1188C189.214 32.1188 190.976 31.3692 192.276 30.0348C193.575 28.7004 194.305 26.8904 194.306 25.0028V7.7234C194.306 5.83517 193.576 4.02428 192.277 2.6891C190.977 1.35392 189.215 0.603828 187.377 0.603828Z" fill="#241916" />
      <path d="M200.228 22.3698L208.028 4.78149C208.578 3.49412 209.48 2.39907 210.626 1.63016C211.771 0.861246 213.11 0.451771 214.478 0.451771C215.846 0.451771 217.184 0.861246 218.33 1.63016C219.475 2.39907 220.378 3.49412 220.927 4.78149L228.721 22.3698C229.49 24.2218 229.513 26.3119 228.783 28.1807C228.053 30.0496 226.631 31.5443 224.829 32.3365C221.505 33.7934 216.666 32.5506 214.483 29.5736C212.303 32.5506 207.461 33.7864 204.137 32.3365C203.243 31.9459 202.433 31.3782 201.753 30.6659C201.073 29.9536 200.536 29.1106 200.173 28.1851C199.81 27.2596 199.628 26.2696 199.637 25.2718C199.647 24.274 199.847 23.2879 200.228 22.3698V22.3698Z" fill="#241916" />
      <path d="M58.1925 0.603832C57.2816 0.602448 56.3795 0.78558 55.5376 1.14275C54.6957 1.49992 53.9306 2.02413 53.286 2.68538C52.6415 3.34663 52.1302 4.13196 51.7813 4.99643C51.4324 5.8609 51.2528 6.78757 51.2528 7.72341C51.2528 5.83518 50.5228 4.02429 49.2233 2.68911C47.9238 1.35393 46.1612 0.603832 44.3235 0.603832C42.4857 0.603832 40.7232 1.35393 39.4236 2.68911C38.1241 4.02429 37.3941 5.83518 37.3941 7.72341V25.0028C37.3941 26.891 38.1241 28.7019 39.4236 30.0371C40.7232 31.3722 42.4857 32.1223 44.3235 32.1223C46.1612 32.1223 47.9238 31.3722 49.2233 30.0371C50.5228 28.7019 51.2528 26.891 51.2528 25.0028C51.2528 26.8915 51.9831 28.7028 53.2829 30.0383C54.5827 31.3738 56.3457 32.1241 58.1839 32.1241C60.0222 32.1241 61.7851 31.3738 63.085 30.0383C64.3848 28.7028 65.115 26.8915 65.115 25.0028V7.72341C65.115 5.8364 64.3859 4.02657 63.0879 2.69159C61.7898 1.35661 60.0291 0.605693 58.1925 0.603832V0.603832Z" fill="#241916" />
      <path d="M299.819 16.2332C299.819 19.4438 298.892 22.5823 297.156 25.2519C295.42 27.9214 292.953 30.0021 290.066 31.2307C287.179 32.4594 284.002 32.7808 280.937 32.1545C277.872 31.5281 275.057 29.982 272.847 27.7118C270.638 25.4415 269.133 22.5491 268.523 19.4001C267.914 16.2512 268.227 12.9872 269.423 10.021C270.618 7.05479 272.643 4.51951 275.242 2.73579C277.84 0.95206 280.895 0 284.019 0C288.21 0 292.228 1.71028 295.191 4.75459C298.154 7.79891 299.819 11.9279 299.819 16.2332V16.2332Z" fill="#241916" />
      <path d="M119.47 0.60383C115.247 0.252766 112.756 2.02915 111.226 4.97809C109.681 2.02915 107.204 0.252766 102.984 0.60383C98.7643 0.954894 96.0547 3.79149 96.0547 7.72341V25.0028C96.0536 26.4182 96.4634 27.8018 97.2316 28.9767C97.9998 30.1517 99.0916 31.0645 100.367 31.5985C101.643 32.1326 103.045 32.2636 104.394 31.9748C105.742 31.686 106.977 30.9906 107.939 29.9773C108.833 30.8309 110.008 31.3052 111.229 31.3052C112.45 31.3052 113.625 30.8309 114.519 29.9773C115.482 30.9893 116.715 31.6837 118.063 31.9718C119.412 32.2599 120.813 32.1286 122.088 31.5947C123.363 31.0608 124.454 30.1485 125.222 28.9743C125.99 27.8002 126.4 26.4175 126.4 25.0028V7.72341C126.4 3.79149 123.653 0.954894 119.47 0.60383Z" fill="#241916" />
    </svg>
  )
}
export default Logo