import { Button, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import anchorLink from 'components/utils/AnchorLink';
import {
  selectConfig,
} from 'features/config/config';

// Introのpropsのtype aliasを定義
type ContentProps = {
  href: string;
  anchor: string;
  children: any;
}
const LinkButton: React.FC<ContentProps> = props => {
  const { href, anchor, children } = props;
  const config = useSelector(selectConfig);
  let history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  function handleClick(e: MouseEvent) {
    e.preventDefault();
    history.push(href);

    if (anchor == "null") {
    } else {
      let offsetY: number = 0;
      if (matches) {
        offsetY = config.scroll.offset;
      } else {
        offsetY = config.scroll.offsetSM;
      }
      anchorLink(anchor, offsetY);
    }
  }

  return (
    <Button color="inherit" className={"button"} style={{ "textTransform": "none" }} onClick={handleClick}>
      {children}
    </Button>
  );
}

export default LinkButton