import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import React from 'react';
import { Link } from "react-router-dom";

type NavProps = {
  data: any;
  baseURL: string;
  direct: number;
}
const PageNav: React.FC<NavProps> = props => {
  const { data, baseURL, direct } = props;

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      next: {
        paddingTop: 0,
      },
    }),
  );

  const classes = useStyles();
  return (
    <div
      id={direct === 0 ? "workPrevious" : "workNext"}
      className={`${classes.next} buttonWrapper`}>
      <Link to={{
        pathname: `${baseURL}${data.url}`,
        state:
        {
          title: data.title,
          hero_image: data.hero_image,
          id: data.id
        }
      }}>
        {direct === 0 ? <ArrowBack fontSize="large" /> : <ArrowForward fontSize="large" />}
      </Link>
    </div>
  );
}
export default PageNav