import {
  ShaderMaterial, Mesh, PlaneBufferGeometry, Vector2, UniformsUtils
} from 'three'
import SceneRTT from 'components/three/render/SceneRTT';
import vertexShader from "components/three/glsl/vert.vert";
import metaball from "components/three/glsl/metaball.frag";

let uniforms = {
  time: { value: 0.0 },
  u_mouse: { type: "v2", value: new Vector2() },
  u_resolution: { type: "v2", value: new Vector2() },
  u_scale: { value: 0.0 },
  uOffset: { value: 0.0 },
  uStep: { value: 0.0 },
}

export default class SceneMetaballRTT extends SceneRTT {
  constructor(w: number, h: number, plane: PlaneBufferGeometry) {
    super(w, h);

    // Noise
    let uniformsNoise = UniformsUtils.clone(uniforms);
    uniformsNoise.u_resolution.value = new Vector2(w, h);

    let materialNoise = new ShaderMaterial({
      uniforms: uniformsNoise,
      vertexShader: vertexShader,
      fragmentShader: metaball
    });

    this.material = materialNoise;
    this.quad = new Mesh(plane, this.material);
    this.scene.add(this.quad);
  }
}