import React from 'react'
import ReactPlayer from "react-player";
import './ResponsivePlayer.scss';

export enum HostType {
  YouTube,
  Vimeo,
}

type TParams = {
  type: HostType,
  id: string,
};

const ResponsivePlayer = (prop: TParams) => {
  const { type, id } = prop;
  let preURL: string = ""
  switch (type) {
    case HostType.YouTube:
      preURL = "https://www.youtube.com/watch?v="
      break;
    case HostType.Vimeo:
      preURL = "https://vimeo.com/"
      break;
    default:
      console.error("No specified type!");
  }

  return (
    <div className='player-wrapper'>
      <ReactPlayer
        className='react-player'
        url={`${preURL}${id}`}
        width='100%'
        height='100%'
      />
    </div>
  )
}
export default ResponsivePlayer